import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import axios from 'axios';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import DialogContent from '@material-ui/core/DialogContent';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useForm } from 'react-hook-form';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/**
 * Function to add the pricing plans
 * @param {*} props
 * @returns
 */
function CourseAdd(props) {
  const {
    openStatusAddCourses, onClosedAddCourses, rowData
  } = props;

  const [schoolYear, setSchoolYear] = useState([]);
  const [courses, setCourses] = useState([]);
  const [schoolYearId, setSchoolYearId] = useState("");
  const [courseId, setCourseId] = useState("");
  const [helperTextType, setHelperTextType] = React.useState();
  const [errorType, setErrorType] = React.useState(false);
  const { register, handleSubmit, errors } = useForm();  
  const endpointAll = 'http://127.0.0.1:8000/api';
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleCloseError = (event, reason) => {
    setOpen(false);
  };

  const handleClose = (event, reason) => {
    setOpenError(false);    
    setSchoolYearId("");
    setCourseId("");
    setCourses([]);
    setSchoolYear([]);
  };

 /**
  * Method to set the list pricing plans
  */
  const getAllSchoolYear = async() => {
    const requestBody = {
      status: "Actual"
    };
    const response = await axios.post(endpointAll+'/school-year/findByStatus' , requestBody);
    setSchoolYear(response.data);
  };

/**
 * Retrieve pricing plan list
 */
  useEffect(() => {
    getAllSchoolYear();  
  }, [openStatusAddCourses]);

  /**
  * Method to set the list pricing plans
  */
  const getAllCourses = async() => {
    const requestBody = {
      status: "Activo",
      year: schoolYearId.id
    };
    const response = await axios.post(endpointAll+'/course/findByStatusAndYear' , requestBody);
    setCourses(response.data);
  };

  /**
   * Retrieve pricing plan list
   */
    useEffect(() => {
      getAllCourses();  
    }, [schoolYearId]);


  /**
   * Method to close the dialog and show the success message
   * @param {data} data
   */
  const handleOK = (data) => {
    onClosedAddCourses();
    setOpen(true);
  };

  /**
   * Method to close the dialog
   */
  const handleError = (response) => {
    if(response.response.data.message.errorInfo[2].includes("Duplicate")){
      setErrorMessage('El profesor ya existe.');
    }else{
      setErrorMessage('La acción no se pudo realizar.');      
    }
    onClosedAddCourses();
    setOpenError(true);
  };

  /**
   * Method to add the register and create que request
   * @param {*} formData
   */
  const onSubmit = async(formData) => {
    const student = rowData;
      const requestBody = {
        course:courseId.id,
        student:student[0]
      };
      await axios.post(endpointAll+'/courseStudent', requestBody).then((response) => {
        handleOK(response);
      })
      .catch((e) => {
        handleError(e);
      });
  };

  /**
   * Method to close the dialog
   */
  const handleCloseAction = () => {
    setSchoolYearId("");
    setCourseId("");
    setCourses([]);
    setSchoolYear([]);
    onClosedAddCourses();
  };

  /**
   * Method to control the combobox of role type
   */
  const handleChangeSchoolYear = (event) => {
    setSchoolYearId(event.target.value);
  };

  /**
   * Method to control the combobox of role type
   */
  const handleChangeCourse = (event) => {
    setCourseId(event.target.value);
  };


  return (
    <Grid container justifyContent="center" direction="column">
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openStatusAddCourses}
        onClose={handleCloseAction}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Añadir</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)} id = "addForm">
            <br/>
              <FormControl width="90%" >
              {Array.isArray(schoolYear)?
                    schoolYear.length === 0 ? "Es necesario tener un año lectivo Actual":
                    <div>
                    <InputLabel required error={errorType} htmlFor="status-helper">Año Lectivo:</InputLabel>
                    <Select
                      name="school_year_id"
                      labelId="demo-checkbox-label"
                      id="school_year_id"
                      fullWidth
                      value={schoolYearId}
                      onChange={handleChangeSchoolYear}
                      input={<Input />}
                      MenuProps={MenuProps}
                      style={{width:"200px"}}
                    >
                      <MenuItem name="" value="">
                        Ninguno
                      </MenuItem>
                      
                      {schoolYear.map((value, key) => (
                        <MenuItem name={value} key={key.toString()} value={value}>
                           {value.year }
                        </MenuItem>
                      ))}
                    </Select>
                    </div>
                    : "Es necesario tener un año lectivo Actual"
                    }
               
                    {errorType && <FormHelperText error>{helperTextType}</FormHelperText>}
                  </FormControl>
                  <br/>
              <FormControl width="90%" >
              {Array.isArray(schoolYear)?
                    schoolYear.length === 0 ? "":
                    Array.isArray(courses)?
                    courses.length === 0 ? "Es necesario tener un curso Activo":
                    <div>
                    <InputLabel required error={errorType} htmlFor="status-helper">Curso:</InputLabel>
                    <Select
                      name="course_id"
                      labelId="demo-checkbox-label"
                      id="course_id"
                      fullWidth
                      value={courseId}
                      onChange={handleChangeCourse}
                      input={<Input />}
                      MenuProps={MenuProps}
                      style={{width:"200px"}}
                    >
                      <MenuItem name="" value="">
                        Ninguno
                      </MenuItem>
                      
                      {courses.map((value, key) => (
                        <MenuItem name={value} key={key.toString()} value={value}>
                           {value.name }
                        </MenuItem>
                      ))}
                    </Select>
                    </div>
                    : "Es necesario tener un curso Activo":""
                    }
               
                    {errorType && <FormHelperText error>{helperTextType}</FormHelperText>}
                  </FormControl>
                  <br/>
          </form>
        </DialogContent>
        <DialogActions>
          <Button id="button_close" onClick={handleCloseAction}>Cerrar</Button>
          {Array.isArray(schoolYear)?
              schoolYear.length === 0 ? 
              <Button disabled id="button_agree_add_course_student" variant="contained" style={{backgroundColor: "#C9F1E8", color: "#FFFFFF"}} type="submit" form="addForm">
                Añadir
              </Button>:
              <Button id="button_agree_add_course_student" variant="contained" style={{backgroundColor: "#68dcb9", color: "#FFFFFF"}} type="submit" form="addForm">
                Añadir
              </Button> : ""
          }
        </DialogActions>
      </Dialog>
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose} >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="success" sx={{ width: '100%' }} center>
          La acción se ejecutó de manera correcta.
        </Alert>
      </Snackbar>
    </Grid>
  );
}

CourseAdd.propTypes = {
  /** status of add dialog */
  openStatus: PropTypes.bool,
  /** function of add dialog */
  onClosed: PropTypes.func,
  /** student data */
  rowData: PropTypes.array.isRequired
};

CourseAdd.defaultProps = {
  openStatus: false,
  onClosed: null,
};

export default CourseAdd;
