import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import axios from 'axios';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useForm } from 'react-hook-form';
import { setListPeriod } from '../actions/periodActions';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/**
 * Function to add the pricing plans
 * @param {*} props
 * @returns
 */
function AddPeriodDialog(props) {
  const {
    openStatus, onClosed
  } = props;

   /**
  * const teacher list
  */
   const endpointSchoolYear = 'http://127.0.0.1:8000/api/school-year/findByStatus';
   const [schoolYear, setSchoolYear] = useState([]);
  const listPeriod = useSelector(state => state.period, shallowEqual);
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();  
  const endpoint = 'http://127.0.0.1:8000/api/period';
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [schoolYearId, setSchoolYearId] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [helperTextType, setHelperTextType] = React.useState();
  const [errorType, setErrorType] = React.useState(false);

  const handleCloseError = (event, reason) => {
    setOpen(false);
  };

  const handleClose = (event, reason) => {
    setOpenError(false);
  };
  
  /**
 * Method to added the new register in the table
 * @param {data} data
 */
  const handleAdd = (data) => {
    const newListPeriod = [...listPeriod];
    newListPeriod.push(data);
    dispatch(setListPeriod(newListPeriod));
  };

  /**
  * Method to set the list pricing plans
  */
  const getAllSchoolYear = async() => {
    const requestBody = {
      status: "Actual"
    };
    const response = await axios.post(endpointSchoolYear , requestBody);
    
    setSchoolYear(response.data);
  };

   /**
     * Retrieve pricing plan list
     */
   useEffect(() => {
    getAllSchoolYear(); 
  }, []);


  /**
   * Method to close the dialog and show the success message
   * @param {data} data
   */
  const handleOK = (data) => {
    handleAdd(data.data);
    onClosed();
    setOpen(true);
  };

  /**
   * Method to close the dialog
   */
  const handleError = (response) => {
    if(response.response.data.message.errorInfo[2].includes("Duplicate")){
      setErrorMessage('El periodo ya existe.');
    }else{
      setErrorMessage('La acción no se pudo realizar.');      
    }
    onClosed();
    setOpenError(true);
  };

  /**
   * Method to add the register and create que request
   * @param {*} formData
   */
  const onSubmit = async(formData) => {
    if (schoolYearId === "") {
      // validate the required field role type
        setHelperTextType("Valor requerido");
        setErrorType(true);
    } else {     
      const requestBody = {
        name: formData.name,
        initial_date: formData.initial_date,
        final_date: formData.final_date,
        school_year_id: schoolYearId.id,
      };
      await axios.post(endpoint, requestBody).then((response) => {
        handleOK(response);
      })
      .catch((e) => {
        handleError(e);
      });
    }
  };

   /**
   * Method to control the combobox of role type
   */
   const handleChangeSchoolYear = (event) => {
    setSchoolYearId(event.target.value);
  };

  /**
   * Method to close the dialog
   */
  const handleCloseAction = () => {
    onClosed();
  };

  return (
    <Grid container justifyContent="center" direction="column">
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openStatus}
        onClose={handleCloseAction}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Añadir</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)} id = "addForm">
            <div >
                <TextField
                required
                name="name"
                margin="dense"
                id="name"
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.name} helperText={errors.name ? errors.name.message : ''}
                label="Periodo"
                type="text"
                fullWidth
              />
              <TextField
                required
                name="initial_date"
                autoFocus
                margin="dense"
                id="initial_date"
                style={{paddingTop: "15px"}}
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.initial_date} helperText={errors.initial_date ? errors.initial_date.message : ''}
                label="Fecha de inicio"
                type="date"
                fullWidth
                defaultValue={new Date()}
              />
              <TextField
                required
                name="final_date"
                margin="dense"
                id="final_date"
                style={{paddingTop: "15px"}}
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.final_date} helperText={errors.final_date ? errors.final_date.message : ''}
                label="Fecha fin"
                type="date"
                fullWidth
                defaultValue={new Date()}
              />

              <br/>
                <FormControl width="90%" >
                {Array.isArray(schoolYear)?
                    schoolYear.length === 0 ? "Es necesario tener un año lectivo Actual":
                    <div>
                    <InputLabel required error={errorType} htmlFor="status-helper">Año Lectivo:</InputLabel>
                    <Select
                      name="school_year_id"
                      labelId="demo-checkbox-label"
                      id="school_year_id"
                      fullWidth
                      value={schoolYearId}
                      onChange={handleChangeSchoolYear}
                      input={<Input />}
                      MenuProps={MenuProps}
                      style={{width:"200px"}}
                    >
                      <MenuItem name="" value="">
                        Ninguno
                      </MenuItem>
                      
                      {schoolYear.map((value, key) => (
                        <MenuItem name={value} key={key.toString()} value={value}>
                           {value.year }
                        </MenuItem>
                      ))}
                    </Select>
                    </div>
                    : "Es necesario tener un año lectivo Actual"
                    }
                  {errorType && <FormHelperText error>{helperTextType}</FormHelperText>}
                </FormControl>
                <br/>
              
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button id="button_close" onClick={handleCloseAction}>Cerrar</Button>
          <Button id="button_agree_add_period" variant="contained" style={{backgroundColor: "#68dcb9", color: "#FFFFFF"}} type="submit" form="addForm">
            Añadir
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose} >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="success" sx={{ width: '100%' }} center>
          La acción se ejecutó de manera correcta.
        </Alert>
      </Snackbar>
    </Grid>
  );
}

AddPeriodDialog.propTypes = {
  /** status of add dialog */
  openStatus: PropTypes.bool.isRequired,
  /** function of add dialog */
  onClosed: PropTypes.func.isRequired
};

export default AddPeriodDialog;
