/**
 * Combine all reducers in this file and export the combined reducers.
 */

import teacher from '../../components/teacher/reducers/teacherReducer';
import parent from '../../components/parent/reducers/parentReducer';
import course from '../../components/course/reducers/courseReducer';
import subject from '../../components/subject/reducers/subjectReducer';
import activity from '../../components/activity/reducers/activityReducer';
import student from '../../components/student/reducers/studentReducer';
import score from '../../components/score/reducers/scoreReducer';
import period from '../../components/period/reducers/periodReducer';
import school_year from '../../components/schoolYear/reducers/schoolYearReducer';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  teacher,
  parent, 
  course, 
  subject,
  activity,
  student,
  score,
  school_year,
  period,
});

export default rootReducer;
