import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setListCourse } from '../actions/courseActions';


const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * Function to confirm the modification of pricing plans
 * @param {*} props
 * @returns
 */
function UpdateConfirmDialog(props) {
  const {
    openStatusConfirm, onClosedConfirm, rowData
  } = props;
   /**
  * const course list
  */
  const listCourse = useSelector(state => state.course, shallowEqual);
  const dispatch = useDispatch();
  const endpoint = 'http://127.0.0.1:8000/api/course/';
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleCloseError = (event, reason) => {
    setOpen(false);
  };

  const handleClose = (event, reason) => {
    setOpenError(false);
  };

//   const dispatch = useDispatch();

  /**
 * Method to modify the new register in the table
 * @param {data} data
 */
  const handleUpdate = (index, obj) => {
    const newListCourse = [...listCourse];
    newListCourse[index] = obj;
    dispatch(setListCourse(newListCourse));
  };

  /**
 * Method to modify the new register in the table
 * @param {data} data
 */
  const handleOK = (data) => {
    for (let cont = 0; cont < listCourse.length; cont += 1) {
      if (listCourse[cont].id === data.id) {
        listCourse[cont] = data;
        handleUpdate(cont, listCourse[cont]);
        break;
      }
    }
    onClosedConfirm();
    setOpen(true);
  };

  const handleError = (response) => {
    if(response.response.data.message.errorInfo[2].includes("Duplicate")){
      setErrorMessage('El curso ya existe.');
    }else{
      setErrorMessage('La acción no se pudo realizar.');      
    }
    onClosedConfirm();
    setOpenError(true);    
  };

  const handleCloseConfirmAction = () => {
    onClosedConfirm();
  };
  /**
   * Method to modify the register and create que request
   * @param {*} formData
   */
  const onUpdate = () => {
    const requestParams = rowData;
    axios.put(endpoint+requestParams.id, requestParams).then((response) => {
      handleOK(response.data);
    })
    .catch((e) => {
      handleError(e);
    });
    //axiosPutService(MODIFY_PRICING_PLANS + rowData.id, requestParams, null, (data) => handleOK(data), () => handleError(), dispatch);
  };

  return (
    <Grid container justifyContent="center" direction="column">
      <Dialog
        open={openStatusConfirm}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseConfirmAction}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title"> Confirmacion</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Esta seguro que desea modificar el curso  {rowData[1]}   
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id="button_close" onClick={handleCloseConfirmAction}> Cerrar </Button>
          <Button id="button_agree_confirm_modify_curso" variant="contained" onClick={onUpdate} style={{backgroundColor: "#68dcb9", color: "#FFFFFF"}}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose} >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="success" sx={{ width: '100%' }} center>
          La acción se ejecutó de manera correcta.
        </Alert>
      </Snackbar>
    </Grid>
  );
}

UpdateConfirmDialog.propTypes = {
  /** status of confirm dialog */
  openStatusConfirm: PropTypes.bool.isRequired,
  /** function of confirm dialog */
  onClosedConfirm: PropTypes.func.isRequired,
  /** Array of pricing plan */
  rowData: PropTypes.object.isRequired,
};

export default UpdateConfirmDialog;
