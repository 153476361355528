import React, { useState, Fragment, useEffect } from "react";
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Link } from "react-router-dom";
import Footer from "../../bootstrap/component/layout/footer-4";
import Header from "../../bootstrap/component/layout/header-5";
import Snackbar from '@mui/material/Snackbar';
import { useForm } from 'react-hook-form';
import MuiAlert from '@mui/material/Alert';
import { saveStorage, getStorage, getRol } from '../../utils/authenticatedUser';
import TextField from '@material-ui/core/TextField';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

function ChangePass(props) { 
    const title = "Cambio de la contraseña";
    const btnText = "Aceptar";
    const endpoint = 'http://127.0.0.1:8000/api/teacher/changePassword';
    const { register, handleSubmit, errors } = useForm();  
    const history = useHistory();
    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [open, setOpen] = useState(false);

     /**
     * Method to close the dialog and show the success message
     * @param {data} data
     */
     const handleOK = (data) => {
        saveStorage(data);
        setOpen(true);
        history.push('/');
    };

    const handleError = (e) => {
        if(e.response.status === 402){
            setErrorMessage(e.response.data.error); 
        }else{
            setErrorMessage('La acción no se pudo realizar.');    
        }  
        setOpenError(true);
    };

    const handleCloseError = (event, reason) => {
        setOpen(false);
    };

    const handleClose = (event, reason) => {
        setOpenError(false);
    };

 /**
     * Method to delete the register and create que request
     * @param {*} formData
     */
    const onSubmit = async(data) => {
        const storage = getStorage();
        const requestBody = {
            email: storage.email,
            oldPass : data.oldPass,
            newPass: data.newPass
          };
          console.log("login facebook", requestBody );
        await axios.post(endpoint, requestBody).then((response) => {
            handleOK(response.data);
        })
        .catch((e) => {
            handleError(e);
        });
    };

    
    return (
        <Fragment>
            <Header />
            <section className="banner-section">
            <div>
                <div className="section-wrapper">
            <div className="login-section padding-tb section-bg">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">{title}</h3>
                        <form className="account-form" onSubmit={handleSubmit(onSubmit)} id = "changePassForm">
                            <div className="form-group">
                            <TextField
                                required
                                name="oldPass"
                                autoFocus
                                margin="dense"
                                id="oldPass"
                                inputRef={register({
                                required: "El campo es requerido",
                                })}
                                error={!!errors.oldPass} helperText={errors.oldPass ? errors.oldPass.message : ''}
                                label="Antigua Contraseña"
                                type="password"
                                width="90%"
                            />
                            </div>
                            <div className="form-group">
                            <TextField
                                required
                                name="newPass"
                                autoFocus
                                margin="dense"
                                id="newPass"
                                inputRef={register({
                                required: "El campo es requerido",
                                })}
                                error={!!errors.newPass} helperText={errors.newPass ? errors.newPass.message : ''}
                                label="Nueva Contraseña"
                                type="password"
                                width="90%"
                            />
                            </div>
                            <div className="form-group text-center">
                                     <Button id="button_change_pass" variant="contained" style={{backgroundColor: "#68dcb9", color: "#FFFFFF"}} type="submit" form="changePassForm">
                                        {btnText}
                                    </Button>
                            </div>
                        </form>
                    </div>
                </div>
                <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose} >
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                {errorMessage}
                </Alert>
                </Snackbar>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseError}>
                    <Alert onClose={handleCloseError} severity="success" sx={{ width: '100%' }} center>
                    La acción se ejecutó de manera correcta.
                    </Alert>
                </Snackbar>
            </div>
            </div>
            </div>
        </section>
            <Footer />
        </Fragment>
    );
}
 
export default ChangePass;