import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import ActionDialogParent from './ActionDialogParent';


function ShowParent (props)  {
  const listParent = useSelector(state => state.parent);
    const [rowDataState, setRowDataState] = useState([]);
    const [statusButton, setStatusButton] = React.useState(false);

      const [tableColumns, setTableColumns] = useState([]);
      const [tableList, setTableList] = useState([]);
      
      const columns = [
        {
          name: 'id',
          options: {
            filter: false,
            display: false,       
            viewColumns: false,
            print: false,
            download: false,
          }
        },
        {
          label: 'Nombre y Apellido',
          name: 'name',
          options: {
            filter: true,
          }
        },
        {
          label: 'Cédula',
          name: 'nit',
          options: {
            filter: true,
          }
        },
        {
          label: 'Dirección',
          name: 'addres',
          options: {
            display:false,
            filter: false,
          }
        },
        {
          label: 'Teléfono',
          name: 'phone',
          options: {
            filter: true,
          }
        },
        {
            label: 'Estado',
            name: 'status',
            options: {
              filter: true,
              customBodyRender: (value) => {
                if(value === 'Activo'){
                  return 'Activo';
                }
                  return 'Removido';
                }
              }
          },
      ];

     /**
 * Method to set the documents list and tabe columns
 */
  useEffect(() => {
    if (listParent && listParent.length >= 0) {
      setTableColumns(columns);
      setTableList(listParent);
    }
  }, [listParent]);
  
      /**
       * Method to recoer the specifid values of the row
       * @param {rowData} rowData
       */
      const handleRowData = (rowData) => {
        const rowDataTemp = [];
        rowDataTemp.length = 0;
        rowDataTemp.push(rowData[0]);
        rowDataTemp.push(rowData[1]);
        rowDataTemp.push(rowData[2]);
        rowDataTemp.push(rowData[3]);
        rowDataTemp.push(rowData[4]);
        rowDataTemp.push(rowData[5]);  
        rowDataTemp.push(rowData[6]); 
        setRowDataState(rowDataTemp);   
      };
    
      const handleCloseButton = () => {
        setStatusButton(false);
      };
    
      const options = {
        selectableRowsHideCheckboxes: true,
        selectableRows: 'single',
        filterType: 'dropdown',
        responsive: 'standard',
        print: true,
        selectableRowsOnClick: false,
        rowsPerPage: 10,
        downloadOptions: {
          filename: 'lp',
          filterOptions: {
            useDisplayedRowsOnly: true,
          }
        },
        onDownload: (buildHead, buildBody, columnsData, data) => {
          const val = '\uFEFF' + `${buildHead(columnsData)}${buildBody(data)}`.trim();
          return val;
        },
        onRowClick: (rowData) => {          
          handleRowData(rowData);
          setStatusButton(true);
        }
      };
    
      return (
        <div>
        <div>
          <MUIDataTable
            title='Padres de Familia'
            data={tableList}
            columns={tableColumns}
            options={options}
          />
          <ActionDialogParent openStatus={statusButton} onClosed= {handleCloseButton} rowData={rowDataState}/>
        </div>
        </div>
      );
}

ShowParent.propTypes = {
  dataParent: PropTypes.string,
};

ShowParent.defaultProps = {
  dataParent: "true"
};

export default ShowParent