import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TeachersAdmin from './TeachersAdmin';
import ParentsAdmin from './ParentsAdmin';


const title = "Administrar";

function TabContainer(props) {
    const { children } = props;
    return (
      <Typography component="div">
        {children}
      </Typography>
    );
  }

TabContainer.propTypes = {
    children: PropTypes.object.isRequired,
  };

  function Administration(props) {
    const [value, setValue] = useState(0);

    const handleChange = (event, valueData) => {
        setValue(valueData);
      };

    return (
        <div className="widget shop-widget">
            <div className="widget-header">
                <h5>{title}</h5>
            </div>
            <div className="widget-wrapper">
                <AppBar position="static" color="default">
                    <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="secondary"
                    >
                        <Tab label="Docentes" />
                        <Tab label="Estudiantes" />
                    </Tabs>
                </AppBar>
                {value === 0 && <TabContainer ><TeachersAdmin/></TabContainer>}
                {value === 1 && <TabContainer ><ParentsAdmin/></TabContainer>}
            </div>
        </div>
    );
}
 
export default Administration;