import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FinishConfirmDialog from './dialogs/FinishConfirmDialog';
import InitConfirmDialog from './dialogs/InitConfirmDialog';
import UpdateTeacherDialog from './dialogs/UpdateSchoolYearDialog';
import SchoolYearForm from './reveal/SchoolYearForm';
import { withStyles } from '@material-ui/core/styles';

function TabContainer(props) {
  const { children } = props;
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.object.isRequired,
};

/**
 * Function to present the reveal of pricing plans and their actions
 * @param {*} props
 * @returns
 */
function ActionDialogSchoolYear(props) {
  const {
    openStatus, onClosed, rowData
  } = props;
  const [statusModify, setStatusModify] = React.useState(false);
  const [statusInit, setStatusInit] = React.useState(false);
  const [statusFinish, setStatusFinish] = React.useState(false);

  const [value, setValue] = useState(0);
  const handleChange = (event, valueData) => {
    setValue(valueData);
  };

  const handleClickOpenModify = () => {
    onClosed();
    setStatusModify(true);
  };

  const handleClickCloseModify = () => {
    setStatusModify(false);
  };

  const handleClickOpenInit = () => {
    onClosed();
    setStatusInit(true);
  };

  const handleClickCloseInit = () => {
    setStatusInit(false);
  };

  const handleClickOpenFinish = () => {
    onClosed();
    setStatusFinish(true);
  };

  const handleClickCloseFinish = () => {
    setStatusFinish(false);
  };

  /**
   * Method to close the dialog
   */
  const handleCloseAction = () => {
    onClosed();
  };

  return (

    <Paper>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openStatus}
        onClose={onClosed}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="user-menu-dialog"> Opciones</DialogTitle>
        <DialogContent>
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              variant="fullWidth"
              scrollButtons="on"
              indicatorColor="#68dcb9"
              textColor="#ffdf40"
            >
              <Tab style={{backgroundColor: "#ffdf4079"}} label="Información" />

            </Tabs>
          </AppBar>
          {value === 0 && <TabContainer><SchoolYearForm rowData={rowData}/></TabContainer>}
         
        </DialogContent>
        <DialogActions>
          <Button id="button_close" onClick={handleCloseAction}> Cerrar </Button>
          {rowData[2] === 'Actual' ? <Button id="button_finish_school_year" variant="contained" onClick={handleClickOpenFinish} style={{backgroundColor: "#68dcb9", color: "#FFFFFF"}}> Terminar
          </Button> : ''}
          {rowData[2] === 'Creado' ? <Button id="button_init_school_year" variant="contained" onClick={handleClickOpenInit} style={{backgroundColor: "#68dcb9", color: "#FFFFFF"}}> Iniciar
          </Button> : ''}
          <Button id="button_modify_school_year" variant="contained" onClick={handleClickOpenModify} style={{backgroundColor: "#68dcb9", color: "#FFFFFF"}}> Modificar
          </Button>
        </DialogActions>
      </Dialog>

      <InitConfirmDialog openStatusConfirm={statusInit} onClosedConfirm= {handleClickCloseInit} rowData={rowData}/>
      <FinishConfirmDialog openStatusConfirm={statusFinish} onClosedConfirm= {handleClickCloseFinish} rowData={rowData}/>
      <UpdateTeacherDialog openStatus={statusModify} onClosed= {handleClickCloseModify} rowData={rowData}/>
    </Paper>

  );
}

ActionDialogSchoolYear.propTypes = {
  /** Style classes */
  openStatus: PropTypes.bool.isRequired,
  /** Function of modify dialog */
  onClosed: PropTypes.func.isRequired,
  /** Array of pricing plan */
  rowData: PropTypes.array.isRequired
};

export default ActionDialogSchoolYear;
