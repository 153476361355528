import React, {useEffect, useState} from 'react'
import { useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import axios from 'axios';
import ActionDialogPeriod from './ActionDialogPeriod';


function ShowPeriod (props)  {
  const listPeriod = useSelector(state => state.period);
  const [schoolYear, setSchoolYear] = useState([]);
  const endpoint = 'http://127.0.0.1:8000/api';
    const [rowDataState, setRowDataState] = useState([]);
    const [statusButton, setStatusButton] = React.useState(false);

      const [tableColumns, setTableColumns] = useState([]);
      const [tableList, setTableList] = useState([]);
      
      const columns = [
        {
          name: 'id',
          options: {
            filter: false,
            display: false,       
            viewColumns: false,
            print: false,
            download: false,     
          }
        },
        {
          label: 'Nombre',
          name: 'name',
          options: {
            filter: true,
          }
        },
        {
            label: 'Fecha de inicio',
            name: 'initial_date',
            options: {
              filter: true,
            }
          },
          {
            label: 'Fecha fin',
            name: 'final_date',
            options: {
              filter: true,
            }
          },
          {
              label: 'Año lectivo',
              name: 'school_year_id',
              options: {
                filter: true,
                customBodyRender: (value) => {
                  const per = value;
                  let namePeriod = '';
                  schoolYear.map((option) => {
                    if (option.id === per) {
                      namePeriod = option.year;
                    }
                    return namePeriod;
                  });
                  return namePeriod;
                },
              }
            },
            {
              name: 'school_year_id',
              options: {
                filter: false,
                display: false,       
                viewColumns: false,
                print: false,
                download: false,
              }
            },
            {
                label: 'Estado',
                name: 'status',
                options: {
                  filter: true,
                }
              },
      ];

     /**
 * Method to set the documents list and tabe columns
 */
  useEffect(() => {
    if (listPeriod && listPeriod.length >= 0) {
      setTableColumns(columns);
      setTableList(listPeriod);
    }
  }, [listPeriod]);
 
   /**
  * Method to set the list pricing plans
  */
   const getAllSchoolYear = async() => {
    const requestBody = {
      status: "Actual"
    };
    const response = await axios.post(endpoint+'/school-year/findByStatus' , requestBody);
    setSchoolYear(response.data);
  };

   /**
   * Retrieve pricing plan list
   */
   useEffect(() => {
    getAllSchoolYear(); 
  }, []);
  
      /**
       * Method to recoer the specifid values of the row
       * @param {rowData} rowData
       */
      const handleRowData = (rowData) => {
        const rowDataTemp = [];
        rowDataTemp.length = 0;
        rowDataTemp.push(rowData[0]);
        rowDataTemp.push(rowData[1]);
        rowDataTemp.push(rowData[2]);
        rowDataTemp.push(rowData[3]);
        rowDataTemp.push(rowData[4]);
        rowDataTemp.push(rowData[5]);
        rowDataTemp.push(rowData[6]);
        setRowDataState(rowDataTemp);   
      };
    
      const handleCloseButton = () => {
        setStatusButton(false);
      };
    
      const options = {
        selectableRowsHideCheckboxes: true,
        selectableRows: 'single',
        filterType: 'dropdown',
        responsive: 'standard',
        print: true,
        selectableRowsOnClick: false,
        rowsPerPage: 10,
        downloadOptions: {
          filename: 'lp',
          filterOptions: {
            useDisplayedRowsOnly: true,
          }
        },
        onDownload: (buildHead, buildBody, columnsData, data) => {
          const val = '\uFEFF' + `${buildHead(columnsData)}${buildBody(data)}`.trim();
          return val;
        },
        onRowClick: (rowData) => {          
          handleRowData(rowData);
          setStatusButton(true);
        }
      };
    
      return (
        <div>
        <div>
          <MUIDataTable
            title='Periodo'
            data={tableList}
            columns={tableColumns}
            options={options}
          />
          <ActionDialogPeriod openStatus={statusButton} onClosed= {handleCloseButton} rowData={rowDataState} schoolYear={schoolYear}/>
        </div>
        </div>
      );
}

ShowPeriod.propTypes = {
  dataPeriod: PropTypes.string,
};

ShowPeriod.defaultProps = {
  dataPeriod: "true"
};

export default ShowPeriod