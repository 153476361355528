export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
export const ROLES = 'ALL';

/** Styled message type */
export const MESSAGE_TYPE = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info'
};

/** Session constants */
export const SESSION_LIFESPAN = 60 * 1000; // 60s
export const SESSION_TIMEOUT = 1500000; // 25min


/** CONTENT TYPE SAVED IN SERVER OF VIDEO RECORDING */
export const OCTET_STREAM = 'application/octet-stream';

/** DATE FRORMAT */
export const DATE_FORMAT = 'DD-MM-YYYY';
export const DEFAULT_INPUT_DATE_FORMAT = 'dd/MM/yyyy';
export const DEFAULT_LOCALE = 'es';
export const DATE_TIME_FORMAT = 'DD-MM-YYYY hh:mm A';

export const link = 'https://localhost:443';

