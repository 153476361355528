import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import AddSubjectDialog from './dialogs/AddSubjectDialog';
import { setListSubject } from './actions/subjectActions';


/**
 * Function to present the filters and search the pricing plans by the filters
 * @param {*} props
 * @returns
 */
function FilterSubject(props) {
  const { classes } = props;
  const endpoint = 'http://127.0.0.1:8000/api';
  const [statusButtonAdd, setStatusButtonAdd] = useState(false);
  const dispatch = useDispatch();
 
  /**
  * Method to set the list pricing plans
  */
  const getAllSubjects = async() => {
    const response = await axios.get(endpoint+'/subjects');
    dispatch(setListSubject(response.data));
    return response.data;
  };
  /**
  * Methods for the add dialog
  */
  const insertSubject = () => {
    setStatusButtonAdd(true);
  };
  const handleCloseButtonAdd = () => {
    setStatusButtonAdd(false);
  };

  /**
   * Retrieve pricing plan list
   */
  useEffect(() => {
    getAllSubjects();  
  }, []);
  
  return (
    <div style={{textAlign:"left"}}>
          <Button  id="insertSubject" variant="contained" style={{backgroundColor: "#ffdf40", color: "#FFFFFF"}} onClick={insertSubject} >Ingresar Materias</Button>
          <AddSubjectDialog openStatus={statusButtonAdd} onClosed= {handleCloseButtonAdd}/>
          <br/>  <br/>
        </div>
  );
}

FilterSubject.propTypes = {
  dataSubject: PropTypes.string,
};

FilterSubject.defaultProps = {
  dataSubject: "true"
};

export default FilterSubject
