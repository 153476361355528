import React, {useState, useEffect}  from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import axios from 'axios';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@mui/material/Button';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


/**
 * Function to present the details of pricing plans
 * @param {*} props
 * @returns
 */
function SubjectTeacherForm(props) {
  const {
    subjects
  } = props;
  
  const [schoolYear, setSchoolYear] = useState([]);
  const [subject, setSubject] = useState([]);
  const [schoolYearId, setSchoolYearId] = useState("");
  const endpoint = 'http://127.0.0.1:8000/api/subject/deleteTeacher/';
  const endpointAll = 'http://127.0.0.1:8000/api';
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleCloseError = (event, reason) => {
    setOpen(false);
  };

  const handleClose = (event, reason) => {
    setOpenError(false);
  };

   /**
  * Method to set the list pricing plans
  */
   const getAllSchoolYear = async() => {
    const requestBody = {
      status: "Actual"
    };
    const response = await axios.post(endpointAll+'/school-year/findByStatus' , requestBody);
    setSchoolYear(response.data);
    const schoolArray = response.data;
    schoolArray.map((value, key) => {
      if(value.status === 'Actual'){
        setSchoolYearId(value);
      }
   })
  };
  
  /**
 * Method to delete the register in the table
 * @param {data} data
 */
  const handleDelete = (index) => {
    const newSubject = [...subject];
    newSubject.splice(index, 1);
    setSubject(newSubject);
  };

  /**
   * Method to close the dialog and show the success message
   * @param {data} data
   */
  const handleOK = (data) => {
    for (let cont = 0; cont < subject.length; cont += 1) {
      if (subject[cont].id === data.id) {
        handleDelete(cont);
        break;
      }
    }
    setOpen(true);
  };

   /**
   * Method to delete the register and create que request
   * @param {*} formData
   */
   const onDelete = async(event) => {
    const id = event.target.value;
    await axios.post(endpoint+id).then((response) => {
      handleOK(response.data);
    })
    .catch((e) => {
      handleError(e);
    });
  };

  const handleError = () => {
    setErrorMessage('La acción no se pudo realizar.');      
    setOpenError(true);
  };

   /**
   * Method to control the combobox of role type
   */
   const handleChangeSchoolYear = (event) => {
    setSchoolYearId(event.target.value);
  };

  /**
 * Method to set the documents list and tabe columns
 */
  useEffect(() => {
    getAllSchoolYear(); 
    setSubject(subjects); 
  }, []);

   /**
 * Method to set the documents list and tabe columns
 */
   useEffect(() => {
    setSubject(subjects);
    console.log('La variable ha cambiado:', schoolYearId);
  }, [schoolYearId]);

  return (
    <form id="teacherForm">
      <FormControl width="90%" >
        {Array.isArray(schoolYear)?
              schoolYear.length === 0 ? "Es necesario tener un año lectivo Actual":
              <div>
              <InputLabel htmlFor="status-helper">Año Lectivo:</InputLabel>
              <Select
                name="school_year_id"
                labelId="demo-checkbox-label"
                id="school_year_id"
                fullWidth
                value={schoolYearId}
                onChange={handleChangeSchoolYear}
                input={<Input />}
                MenuProps={MenuProps}
                style={{width:"200px"}}
              >
                <MenuItem name="" value="">
                  Ninguno
                </MenuItem>
                
                {schoolYear.map((value, key) => (
                  <MenuItem name={value} key={key.toString()} value={value}>
                      {value.year}
                  </MenuItem>
                ))}
              </Select>
              </div>
              : ""
              }
            </FormControl>
            <br/><br/>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableRow style={{backgroundColor: "#68dcb9", color: "#FFFFFF"}}>
                <TableCell align="center">Año Lectivo</TableCell>
                <TableCell align="center">Curso</TableCell>
                <TableCell align="center">Materia</TableCell>
                <TableCell align="center">Acción</TableCell>
              </TableRow>
            </TableHead>
            
            {subject.map((value, key) => (
            <TableBody>
                {value.year === schoolYearId.year ? 
                <TableRow >                  
                  <TableCell align="center"> {value.year}</TableCell>
                  <TableCell align="center">{value.course}</TableCell>
                  <TableCell align="center">{value.name}</TableCell>
                  <TableCell align="center"><Button style={{color: "#68dcb9", borderColor: "#68dcb9", border:"double" }} startIcon={<DeleteIcon/>} onClick={onDelete} value={value.id}>Eliminar</Button></TableCell>
                </TableRow>
                :""}
            </TableBody>
            ))}
          </Table>
          <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose} >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="success" sx={{ width: '100%' }} center>
          La acción se ejecutó de manera correcta.
        </Alert>
      </Snackbar>
        </Grid>


      </Grid>
    </form>
  );
}

SubjectTeacherForm.propTypes = {
  subjects: PropTypes.array.isRequired
};

export default SubjectTeacherForm;
