import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

/**
 * Function to present the details of pricing plans
 * @param {*} props
 * @returns
 */
function ParentForm(props) {
  const {
    rowData
  } = props;


  return (
    <form id="parentForm">
      <Grid container spacing={3}>
        <Grid item xs={12}>

          <Table>
            <TableBody>
              <TableRow >
                <TableCell padding="default" >Nombre y Apellido:</TableCell>
                <TableCell align="center">{rowData[1]}</TableCell>
              </TableRow>

              <TableRow >
                <TableCell padding="default" >Cédula:</TableCell>
                <TableCell align="center">{rowData[2]}</TableCell>
              </TableRow>

              <TableRow >
                <TableCell padding="default" >Dirección:</TableCell>
                <TableCell align="center">  {rowData[3]}</TableCell>
              </TableRow>

              <TableRow >
                <TableCell padding="default" >Teléfono:</TableCell>
                <TableCell align="center">{rowData[4]}</TableCell>
              </TableRow>

              <TableRow >
                <TableCell padding="default" >Estado:</TableCell>
                <TableCell align="center">{rowData[5]}</TableCell>
              </TableRow>

            </TableBody>
          </Table>

        </Grid>


      </Grid>
    </form>
  );
}

ParentForm.propTypes = {
  rowData: PropTypes.array.isRequired
};

export default ParentForm;
