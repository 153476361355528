import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import AddPeriod from './dialogs/AddPeriodDialog';
import { setListPeriod } from './actions/periodActions';


/**
 * Function to present the filters and search the pricing plans by the filters
 * @param {*} props
 * @returns
 */
function FilterPeriod(props) {
  const { classes } = props;
  const endpoint = 'http://127.0.0.1:8000/api';
  const [statusButtonAdd, setStatusButtonAdd] = useState(false);
  const dispatch = useDispatch();
 
  /**
  * Method to set the list pricing plans
  */
  const getAllPeriod = async() => {
    const response = await axios.get(endpoint+'/period');
    dispatch(setListPeriod(response.data));
    return response.data;
  };
  /**
  * Methods for the add dialog
  */
  const insertPeriod = () => {
    setStatusButtonAdd(true);
  };
  const handleCloseButtonAdd = () => {
    setStatusButtonAdd(false);
  };

  /**
   * Retrieve pricing plan list
   */
  useEffect(() => {
    getAllPeriod();  
  }, []);
  
  return (
    <div style={{textAlign:"left"}}>
          <Button  id="insertPeriod" variant="contained" style={{backgroundColor: "#ffdf40", color: "#FFFFFF"}} onClick={insertPeriod} >Ingresar Periodo</Button>
          <AddPeriod openStatus={statusButtonAdd} onClosed= {handleCloseButtonAdd}/>
          <br/>  <br/>
        </div>
  );
}

FilterPeriod.propTypes = {
  dataTeacher: PropTypes.string,
};

FilterPeriod.defaultProps = {
  dataTeacher: "true"
};

export default FilterPeriod
