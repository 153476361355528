import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import axios from 'axios';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector, shallowEqual } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import AddConfirmDialog from './AddConfirmDialog';
import { useForm } from 'react-hook-form';


/**
 * Function to add the pricing plans
 * @param {*} props
 * @returns
 */
function AddSubjectDialog(props) {
  const {
    openStatus, onClosed
  } = props;

  const endpointSchoolYear = 'http://127.0.0.1:8000/api/school-year/findByStatus';
  const endpointAll = 'http://127.0.0.1:8000/api';
  const [course, setCourse] = useState([]);

   /**
  * const subjetc list
  */
  const { register, handleSubmit, errors } = useForm(); 
  const [schoolYear, setSchoolYear] = useState([]);
  const [statusConfirm, setStatusConfirm] = React.useState(false); 
  const [modifiedData, setModifiedData] = React.useState({});
  const [courseId, setCourseId] = useState([]);
  const [helperTextType, setHelperTextType] = React.useState();
  const [errorType, setErrorType] = React.useState(false);

  const handleCloseConfirm = () => {
    setStatusConfirm(false);
  };

  /**
  * Method to set the list pricing plans
  */
  const getAllSchoolYear = async() => {
    const requestBody = {
      status: "Actual"
    };
    const response = await axios.post(endpointSchoolYear , requestBody);
    
    setSchoolYear(response.data);
  };

   /**
  * Method to set the list pricing plans
  */
   const getAllActiveCourse = async() => {
    const requestBody = {
      status: "Activo"
    };
    const response = await axios.post(endpointAll+'/course/findByStatus' , requestBody);
    setCourse(response.data);
  };

  /**
   * Retrieve pricing plan list
   */
  useEffect(() => {
    getAllActiveCourse();  
    getAllSchoolYear(); 
  }, [openStatus]);

  /**
   * Method to add the register and create que request
   * @param {*} formData
   */
  const onSubmit = async(formData) => {
    if (courseId === "") {
      // validate the required field role type
        setHelperTextType("Valor requerido");
        setErrorType(true);
    } else {
      const requestBody = {
        name: formData.name,
        description: formData.description, 
        course_id: courseId
      };

      console.log(requestBody.course_id);
      setModifiedData(requestBody);     
      onClosed();
      setErrorType(false); 
      setStatusConfirm(true);
    }
  };

  /**
   * Method to close the dialog
   */
  const handleCloseAction = () => {
    onClosed();
  };

  /**
  * Method to control the check box selection
  */
  const handleChangeCourse = (event) => {
    const newListCourse = [...courseId];
    if(event.target.checked){
      //incluir
      newListCourse.push(event.target.value);
    }else{
      //quitar
      for (let cont = 0; cont < courseId.length; cont += 1) {
        if (courseId[cont] === event.target.value) {
          newListCourse.splice(cont, 1);
          break;
        }
      }
    }
   setCourseId(newListCourse);
 };


  return (
    <Grid container justifyContent="center" direction="column">
      <Dialog
        fullWidth
        maxWidth="sm"
        open={openStatus}
        onClose={handleCloseAction}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Añadir</DialogTitle>
        <DialogContent>
          <form noValidate onSubmit={handleSubmit(onSubmit)} id = "addForm">
            <div >
                <TextField
                required
                name="name"
                autoFocus
                margin="dense"
                id="name"
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.name} helperText={errors.name ? errors.name.message : ''}
                label="Nombre"
                type="text"
                width="90%"
              />
               <TextField
                required
                name="description"
                autoFocus
                margin="dense"
                multiline
                rows={4}
                id="description"
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.description} helperText={errors.description ? errors.description.message : ''}
                label="Descripción"
                type="text"                
                style={{marginLeft:"120px"}}
              />
               <br/>
                <FormControl fullWidth>
                <InputLabel required error={errorType} htmlFor="status-helper">Cursos:</InputLabel>
                <br/>   <br/> 
                  <FormGroup>
                      {course.map((value, key) => (
                        <FormControlLabel control={<Checkbox />}  onChange={handleChangeCourse} value={value.id} label={value.name}/>
                      ))}
                  </FormGroup>
                  {errorType && <FormHelperText error>{helperTextType}</FormHelperText>}
                </FormControl>

                <FormControl width="90%" >
                {Array.isArray(schoolYear)?
                    schoolYear.length === 0 ? "Es necesario tener un año lectivo Actual":
                    ""
                    : "Es necesario tener un año lectivo Actual"
                    }
                </FormControl>
                           
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button id="button_close" onClick={handleCloseAction}>Cerrar</Button>
          {Array.isArray(schoolYear)?
              schoolYear.length === 0 ? 
              <Button disabled id="button_agree_add_teacher" variant="contained" style={{backgroundColor: "#C9F1E8", color: "#FFFFFF"}} type="submit" form="addForm">
                Añadir
              </Button>:
              <Button id="button_agree_add_subject" variant="contained" style={{backgroundColor: "#68dcb9", color: "#FFFFFF"}} type="submit" form="addForm">
                Añadir
              </Button> : ""
          }
        </DialogActions>
      </Dialog>
      <AddConfirmDialog openStatusConfirm={statusConfirm} onClosedConfirm={handleCloseConfirm} rowData={modifiedData}/>
    </Grid>
  );
}

AddSubjectDialog.propTypes = {
  /** status of add dialog */
  openStatus: PropTypes.bool.isRequired,
  /** function of add dialog */
  onClosed: PropTypes.func.isRequired
};

export default AddSubjectDialog;
