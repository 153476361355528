import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import AddSchoolYear from './dialogs/AddSchoolYearDialog';
import { setListSchoolYear } from './actions/schoolYearActions';


/**
 * Function to present the filters and search the pricing plans by the filters
 * @param {*} props
 * @returns
 */
function FilterSchoolYear(props) {
  const { classes } = props;
  const endpoint = 'http://127.0.0.1:8000/api';
  const [statusButtonAdd, setStatusButtonAdd] = useState(false);
  const dispatch = useDispatch();
 
  /**
  * Method to set the list pricing plans
  */
  const getAllSchoolYears = async() => {
    const response = await axios.get(endpoint+'/school-year');
    dispatch(setListSchoolYear(response.data));
    return response.data;
  };
  /**
  * Methods for the add dialog
  */
  const insertSchoolYear = () => {
    setStatusButtonAdd(true);
  };
  const handleCloseButtonAdd = () => {
    setStatusButtonAdd(false);
  };

  /**
   * Retrieve pricing plan list
   */
  useEffect(() => {
    getAllSchoolYears();  
  }, []);
  
  return (
    <div style={{textAlign:"left"}}>
          <Button  id="insertSchoolYear" variant="contained" style={{backgroundColor: "#ffdf40", color: "#FFFFFF"}} onClick={insertSchoolYear} >Ingresar Año Lectivo</Button>
          <AddSchoolYear openStatus={statusButtonAdd} onClosed= {handleCloseButtonAdd}/>
          <br/>  <br/>
        </div>
  );
}

FilterSchoolYear.propTypes = {
  dataTeacher: PropTypes.string,
};

FilterSchoolYear.defaultProps = {
  dataTeacher: "true"
};

export default FilterSchoolYear
