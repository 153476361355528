/** Reducer for List period */

import { fromJS } from 'immutable';
import { SET_LIST_PERIOD } from '../actions/periodConst';

export const initialState = {
  listPeriod: []
};

const initialImmutableState = fromJS(initialState);

const period = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_LIST_PERIOD:
      state = initialState;
      return state.listPeriod.concat(action.period);
    default:
      return state;
  }
};

export default period;
