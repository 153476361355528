import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import axios from 'axios';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useForm } from 'react-hook-form';
import { setListTeacher } from '../actions/teacherActions';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

/**
 * Function to add the pricing plans
 * @param {*} props
 * @returns
 */
function AddTeacherDialog(props) {
  const {
    openStatus, onClosed
  } = props;

   /**
  * const teacher list
  */
  const listTeacher = useSelector(state => state.teacher, shallowEqual);
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();  
  const endpoint = 'http://127.0.0.1:8000/api/teacher';
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [subjects, setSubjects] = useState([]);

  const handleCloseError = (event, reason) => {
    setOpen(false);
  };

  const handleClose = (event, reason) => {
    setOpenError(false);
  };

  
  //const dispatch = useDispatch();

  
  /**
 * Method to added the new register in the table
 * @param {data} data
 */
  const handleAdd = (data) => {
    const newListTeacher = [...listTeacher];
    newListTeacher.push(data);
    dispatch(setListTeacher(newListTeacher));
  };


  /**
   * Method to close the dialog and show the success message
   * @param {data} data
   */
  const handleOK = (data) => {
    handleAdd(data.data);
    onClosed();
    setOpen(true);
  };

  /**
   * Method to close the dialog
   */
  const handleError = (response) => {
    if(response.response.data.message.errorInfo[2].includes("Duplicate")){
      setErrorMessage('El profesor ya existe.');
    }else{
      setErrorMessage('La acción no se pudo realizar.');      
    }
    onClosed();
    setOpenError(true);
  };

  /**
   * Method to add the register and create que request
   * @param {*} formData
   */
  const onSubmit = async(formData) => {
        
      const requestBody = {
        name: formData.name,
        email:formData.email,
        phone:formData.phone,
        birthday:formData.birthday,
        nit:formData.nit
      };
      await axios.post(endpoint, requestBody).then((response) => {
        handleOK(response);
      })
      .catch((e) => {
        handleError(e);
      });
  };

  /**
   * Method to close the dialog
   */
  const handleCloseAction = () => {
    onClosed();
  };

  return (
    <Grid container justifyContent="center" direction="column">
      <Dialog
        fullWidth
        maxWidth="md"
        open={openStatus}
        onClose={handleCloseAction}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Añadir</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)} id = "addForm">
            <div >
                <TextField
                required
                name="name"
                autoFocus
                margin="dense"
                id="name"
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.name} helperText={errors.name ? errors.name.message : ''}
                label="Nombre y Apellido"
                type="text"
                fullWidth
              />
              <TextField
                required
                name="nit"
                autoFocus
                margin="dense"
                id="nit"
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.nit} helperText={errors.nit ? errors.nit.message : ''}
                label="Cédula"
                type="text"
              />
               <TextField
                required
                name="phone"
                autoFocus
                margin="dense"
                id="phone"
                inputRef={register({
                  required: "El campo es requerido",
                })}
                error={!!errors.phone} helperText={errors.phone ? errors.phone.message : ''}
                label="Teléfono"
                type="text"
                style={{marginLeft:"120px"}}
              />
               <TextField
                required
                name="email"
                autoFocus
                margin="dense"
                id="email"
                inputRef={register({
                  pattern: { value: EMAIL_REGEX, message: "Correo electrónico inválido" },
                  required: "El campo es requerido",
                })}
                error={!!errors.email} helperText={errors.email ? errors.email.message : ''}
                label="Correo eléctronico"
                type="email"
                fullWidth
              />              
            </div>            
          </form>
        </DialogContent>
        <DialogActions>
          <Button id="button_close" onClick={handleCloseAction}>Cerrar</Button>
          <Button id="button_agree_add_teacher" variant="contained" style={{backgroundColor: "#68dcb9", color: "#FFFFFF"}} type="submit" form="addForm">
            Añadir
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose} >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="success" sx={{ width: '100%' }} center>
          La acción se ejecutó de manera correcta.
        </Alert>
      </Snackbar>
    </Grid>
  );
}

AddTeacherDialog.propTypes = {
  /** status of add dialog */
  openStatus: PropTypes.bool.isRequired,
  /** function of add dialog */
  onClosed: PropTypes.func.isRequired
};

export default AddTeacherDialog;
